<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <v-col>
                <v-card color="blue lighten-5">
                    <v-container fluid>
                       <v-row>
                        <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="regionModel" 
                                        :items="regionLists" 
                                        item-value="region" 
                                        item-text="region" 
                                        label="Select Region" 
                                        :disabled="disabled_region == true" 
                                        @change="(event) => updateOfficeDropdown(event)" 
                                        clearable 
                                        prepend-icon="mdi-city"
                                        >
                                    </v-autocomplete>
                            </v-col>
                            <!-- <div class="col-lg-2 col-6">
                                <v-select 
                                v-model="regionModel" 
                                :items="regionLists" 
                                default="" 
                                item-value="region" 
                                item-text="region" 
                                label="Select Region" 
                                :disabled="disabled_region == true" 
                                @change="(event) => updateOfficeDropdown(event)" 
                                clearable
                                dense
                                prepend-icon="mdi-city"></v-select>
                            </div> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="officeModel" 
                                        :items="officeLists" 
                                        item-value="office_id" 
                                        item-text="office" 
                                        label="Select branch" 
                                        :disabled="disabled_office == true" 
                                        clearable 
                                        prepend-icon="mdi-home-variant"
                                        >
                                    </v-autocomplete>
                            </v-col>
                            <!-- <div class="col-lg-2 col-6">
                                <v-select 
                                v-model="officeModel" 
                                :items="officeLists" 
                                default="" 
                                item-value="office_id" 
                                item-text="office" 
                                label="Select branch" 
                                :disabled="disabled_office == true" 
                                clearable 
                                dense 
                                prepend-icon="mdi-home-variant"></v-select>
                            </div> -->
                            <!-- <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div> -->
                            <!-- <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="dialog"
                                    v-model="dateStartModal" 
                                    :return-value.sync="dateStartModel" 
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateStartModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" 
                                    @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="dateEndModal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateEndModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" 
                                    :show-current="true" 
                                    @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- <div class="col-lg-2 col-6">
                                <v-select v-model="responseModel" :items="responseLists" default="" item-value="value" item-text="text" label="Need Response" dense prepend-icon="mdi-home-variant"></v-select>
                            </div> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="responseModel" 
                                        :items="responseLists" 
                                        item-value="value" 
                                        item-text="text"
                                        label="Need Response"
                                        clearable 
                                        prepend-icon="mdi-home-variant"
                                        >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn class="mt-3" 
                                    color="info" elevation="2" large @click="getData()">Search</v-btn>
                                </v-col> 
                            <!-- <div class="col-lg-2 col-6 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div> -->
                        </v-row>
                        <div class="col-lg-12 cardResult">
                <div class="card elevation-5  mt-4">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.showDetails`]="{ item }">
                                <div v-if="item.need_response == 'Y' && item.user_response == ''">
                                    <v-chip @click="showItemDetails(item)" color="red" small link v-on="on" dark>NEED RESPONSE</v-chip>
                                </div>
                                <div v-else>
                                    <v-chip @click="showItemDetails(item)" color="primary" small link v-on="on" dark>Details</v-chip>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
                    </v-container>
                </v-card>
            </v-col>
            <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
                <div class="modal-dialog" style="max-width: 80%;">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="detailModalLabel">Detail Order of </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-2">
                                <v-text-field v-model="custIDModel" label="ID" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-4">
                                <v-text-field v-model="custNameModel" label="Name" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-3">
                                <v-text-field v-model="custPhoneModel" label="Phone" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-3">
                                <v-text-field v-model="kindBusinessModel" label="Business" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-8">
                                <v-text-field v-model="custAddressModel" label="Address" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-4">
                                <v-text-field v-model="custCityModel" label="City" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-6">
                                <v-textarea v-model="telemarketingRemarkModel" label="TELEMARKETING REMARK" rows="2" :readonly="true" dense outlined></v-textarea>
                            </div>
                            <div class="col-lg-6">
                                <v-textarea v-model="branchRemarkModel" label="BRANCH RESPONSE" rows="2" dense outlined></v-textarea>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="submitBranchRemark()">SUBMIT<v-icon right dark>mdi-checkbox-marked-circle</v-icon></v-btn>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>


export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Sales Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Telemarketing',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            officeModel: '',
            officeLists: [],
            responseModel: '',
            responseLists: [
                {
                    text: "ALL",
                    value: "",
                },
                {
                    text: "YES",
                    value: "Y",
                },
                {
                    text: "NO",
                    value: "N",
                }
            ],
            dateStartModel: '',
            dateEndModel: '',
            dateStartModal: false,
            dateEndModal: false,
            disabled_region: false,
            disabled_office: false,
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            headers: [
                { text: 'DATE', value: 'tr_date1', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUST. NAME', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'BUSINESS TYPE', value: 'kind_of_business', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CITY', value: 'Kota', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RESPONSE', value: 'user_response', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            telemarketingID: '',
            custIDModel: '',
            custNameModel: '',
            custPhoneModel: '',
            kindBusinessModel: '',
            custAddressModel: '',
            custCityModel: '',
            telemarketingRemarkModel: '',
            branchRemarkModel: ''
        }
    },
    created() {
        
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        initialize(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none"

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            axios.get(`${process.env.VUE_APP_URL}/api/kmb/Telemarketing`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },    
        
        async getData(){

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/Telemarketing/getData`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    needResponse: this.responseModel ? this.responseModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        icon: 'warning',
                        text: 'Data not found !'
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async showItemDetails(id){

            $('#detailModal').modal('show')
            this.itemDetailLists = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/Telemarketing/getDataDetails`, { 
                    cust_id: id.customerid
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.telemarketingID = res.data.result[0].id
                this.custIDModel = res.data.result[0].customerid
                this.custNameModel = res.data.result[0].NamaCustomer
                this.custPhoneModel = res.data.result[0].Telepon
                this.kindBusinessModel = res.data.result[0].kind_of_business
                this.custAddressModel = res.data.result[0].alamat
                this.custCityModel = res.data.result[0].Kota
                this.telemarketingRemarkModel = res.data.result[0].remark
                this.branchRemarkModel = res.data.result[0].user_response

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async submitBranchRemark(){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/Telemarketing/submitBranchRemark`, { 
                    id: this.telemarketingID,
                    remark: this.branchRemarkModel ? this.branchRemarkModel : '',
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                if(res.data.result == 'OK'){
                    Swal.fire({
                        icon: 'success',
                        text: 'Successfully submitted'
                    })
                    $('#detailModal').modal('hide')
                    this.getData()
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },
        
        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>